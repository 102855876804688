/* Section Title */
.section-title:after {
  background: #ff8100;
}

.caption {
  color: #111;
}

.gp-bn-btn {
  background-color: #ff8100;
}

.gp-bn-active {
  background-color: transparent;
}

.gp-btn {
  border-color: #ff8100;
}

.gp-btn:hover, .gp-btn:focus {
  background: #ff8100;
}

/* Header */
#header .navbar-nav li a:after {
  background: #ff8100;
}

#header .navbar-nav li a:hover, #header .navbar-nav li a:focus {
  color: #ff8100;
}

#header .navbar-nav li .child-menu li:hover {
  background: #ff8100;
}

#header .navbar-nav li.active a {
  color: #ff8100 !important;
}

.navbar-fixed-top .navbar-nav li a:hover {
  color: #ff8100 !important;
}

/* Back To Top*/
.return-to-top {
  background: #ff8100;
}

.mobile_menu_search #searchsubmit:hover {
  color: #ff8100;
}

/* Banner */
.banner-next:hover, .banner-next:hover {
  background: #ff8100;
}

.banner-prev:hover, .banner-prev:hover {
  background: #ff8100;
}

.swiper-pagination-bullet-active {
  background: #ff8100;
}

.slider-caption-one h2:before, .slider-caption-one h2:after {
  background: #ff8100;
}

.slider-caption-two h4 {
  color: #ff8100;
}

.slider-caption-three h2 {
  color: #ff8100;
}

/* About */
.about-box:hover .icon-box {
  background: #ff8100;
}

.about-box:hover .about-title {
  color: #ff8100;
}

/* Aboute Two */
.about-two .about-box:hover i {
  color: #ff8100;
}

/* Skills */
progress::-webkit-progress-value {
  background-color: #ff8100;
}

/* Skills Two */
.our-skills-two .skill-bar .skills-title h2 {
  color: #ff8100;
}

.skills-description h2:after {
  background: #ff8100;
}

.skills-description .gp-btn {
  background: #ff8100;
}

/* Service */
.service-item .service .icon-box .icon-overlay {
  background: #ff8100;
}

.service-item .service:hover .service_content .title {
  color: #ff8100;
}

/* Service Two */
.service-two .service i {
  color: #ff8100;
}

.service-two .service .overlay {
  background: #ff8100;
}

/* Portfolio */
.portfolio .portfolioFilter li a.current, .portfolio .portfolioFilter li a:hover {
  background-color: #ff8100;
}

.portfolio_container .portfolio-item .overlay {
  background-color: rgba(255, 129, 0, 0.8);
}

/* Countup */
.counter-box {
  border-color: #ff8100;
}

.counter-box .count i {
  color: #ff8100;
}

/* CountUp Two */
.countup-two .overlay {
  background-color: rgba(255, 129, 0, 0.3);
}

/* Team */
.team_member {
  border-color: #ff8100;
}

.team_member .team_intro {
  background-color: rgba(255, 129, 0, 0.8);
}

.team_member:hover .team_intro .team_link li:hover a i {
  color: #ff8100;
}

/* Team Two */
#team-slider .team_member {
  border-color: #ff8100;
}

/* Blog */
.blog_post .blog-thumb .blog-overlay {
  background-color: rgba(255, 129, 0, 0.8);
}

.blog_post .blog_content .blog_title a:hover {
  color: #ff8100;
}

.blog_post .blog_content .gp-btn:hover {
  background: #ff8100;
}

.blog-comments .blog-social li a:hover {
  color: #ff8100;
}

/* Blog Two */
.blog-two .blog_post .blog-thumb-left .blog-comments {
  background: rgba(255, 129, 0, 0.8);
}

.blog-two .blog_post .blog-thumb-right .blog-comments {
  background: rgba(255, 129, 0, 0.8);
}

.more-blog {
  background: #ff8100;
}

/* Blog Single */
.blog-wrapper .post-meta li a {
  color: #ff8100;
}

.blog-wrapper blockquote {
  color: #ff8100;
  border-color: #ff8100;
}

.blog-wrapper .tag-area i {
  color: #ff8100;
}

.blog-wrapper .tag-area a:hover {
  color: #ff8100;
}

.blog-sub-title:after {
  background: #ff8100;
}

article .reply a {
  color: #ff8100;
}

.comment-respond #submit {
  background: #ff8100;
}

/* Testimonial */
.testimonial-slider .testional .item .cliten-thumbs {
  border-color: #ff8100;
}

.testimonial-slider .testional .item h4 {
  color: #ff8100;
}

.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  background: #ff8100;
}

#feedback-slider2 .item .testi-content h4 {
  color: #ff8100;
}

/* Pricing Table */
.table .pricing_plan .price {
  background: #ff8100;
}

.table .pricing_plan .configuration li .gp-btn {
  background: #ff8100;
}

/* Brand */
.brand .item {
  border-color: #ff8100;
}

/* News Letter */
#newsletter .news-wraper h3 {
  color: #ff8100;
}

#newsletter .news-wraper form {
  border-color: #ff8100;
}

#newsletter .news-wraper form button {
  background: #ff8100;
}

/* Contact */
.contact-form-area .contact-info .info span i {
  color: #ff8100;
}

.contact-form-area .contact-info .info h4 {
  color: #ff8100;
}

#form input[type=text]:hover, #form textarea:hover {
  border-color: #ff8100;
}

#form .gp-btn {
  background: #ff8100;
}

/* Contact Two */
#contact.contact-two .contact-form-area #form input[type=text]:hover, #contact.contact-two .contact-form-area #form textarea:hover {
  border-color: #ff8100;
}

/* Footer */
#footer {
  background: #ff8100;
}

.copyright {
  border-color: #ea9215;
}

/* Footer Two */
#footer.footer .info i {
  color: #ff8100;
}

#footer.footer .gp-social-link li a:hover {
  background: #ff8100;
}

#footer.footer .news-wrapper:hover .news-content a span {
  color: #ff8100;
}

/* Widget */
.widget .widget-title:after {
  background: #ff8100;
}

.widget_nav_menu ul li a:hover {
  color: #ff8100;
}

.widget_recent_entries .resent-post .post-content .post-meta li a {
  color: #ff8100;
}

.widget_archive ul li:hover a {
  color: #ff8100;
}

.widget_archive ul li:hover p {
  color: #ff8100;
}

.dt-tag a:hover {
  background: #ff8100;
}
